import { useTheme } from 'next-themes'
import { useEffect } from 'react'
import { ToastMessage } from 'remix-toast'
import { toast as notify, Toaster as Sonner } from 'sonner'
import { exhaustiveGuard } from '~/lib/utils'

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = 'system' } = useTheme()

  return (
    <Sonner
      theme={theme as ToasterProps['theme']}
      className="toaster group"
      toastOptions={{
        classNames: {
          toast:
            'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
          description: 'group-[.toast]:text-muted-foreground',
          actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
        },
      }}
      {...props}
    />
  )
}

const useToast = (toast: ToastMessage | undefined) => {
  useEffect(() => {
    if (!toast) {
      return
    }

    switch (toast.type) {
      case 'error':
        notify.error(toast.message, {
          description: toast.description,
        })
        break
      case 'info':
        notify.info(toast.message, {
          description: toast.description,
        })
        break
      case 'success':
        notify.success(toast.message, {
          description: toast.description,
        })
        break
      case 'warning':
        notify.warning(toast.message, {
          description: toast.description,
        })
        break
      default:
        exhaustiveGuard(toast.type, `Unhandled toast type: ${toast.type}`)
    }
  }, [toast])
}

export { Toaster, useToast }
